<template>
  <div :class="{ 'animate-pulse': fetching }">
    <ul v-if="sideCategories && !fetching">
      <li v-if="back">
        <div class="border-b border-gray-300 py-2">
          <FimButton
            type="tertiary"
            is-link
            is-small
            :to="localePath(back)"
            text-size="md"
            active-class="font-medium"
            class="align-center flex size-full !justify-start"
          >
            <template #icon>
              <LazyIconFielmannArrowLeft class="size-3" />
            </template>
            {{ $t('navigation.back') }}
          </FimButton>
        </div>
      </li>
      <li v-for="(category, index) in childrenCategories" :key="index">
        <div class="border-b border-gray-300 py-2">
          <FimButton
            type="tertiary"
            is-link
            is-small
            :to="localePath(category.path)"
            text-size="md"
            active-class="font-medium"
            class="align-center flex size-full justify-between !whitespace-normal"
            data-testid="list_subcategory"
            @click="$emit('click:item', category)"
          >
            {{ category.name }}
          </FimButton>
        </div>
      </li>
    </ul>
    <template v-else>
      <LazySideNavigationSkeleton
        v-for="index in 8"
        :key="`category-loading-${index}`"
        :index="index"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Category } from '@scayle/storefront-nuxt'

const localePath = useFimLocalePath()

defineEmits(['click:item'])

const props = defineProps({
  categories: {
    type: [Array, Object] as PropType<Category[] | Category>,
    default: () => [],
  },
  selectedCategory: {
    type: Object as PropType<Category>,
    default: () => undefined,
  },
  fetching: {
    type: Boolean,
    required: true,
  },
})

const flattenCategories = (categories: Category[] | Category): Category[] => {
  if (!Array.isArray(categories)) {
    categories = [categories]
  }

  return categories.flatMap((category) => {
    if (category.children) {
      return [category, ...flattenCategories(category.children)]
    }

    return category
  })
}

const categories = computed(() => flattenCategories(props.categories))
const sideCategories = computed(() => {
  if (props.selectedCategory?.childrenIds?.length) {
    return props.selectedCategory
  }

  if (props.selectedCategory?.parentId) {
    return (
      categories.value.find(
        (category) => category.id === props?.selectedCategory?.parentId,
      ) || props.categories
    )
  }

  return props.categories
})

const removeLastPathNode = (path: string) =>
  path.split('/').slice(0, -1).join('/')

const back = computed(() => {
  if (Array.isArray(sideCategories.value) || sideCategories.value.depth <= 1) {
    return ''
  }

  const isLeafCategory = !props.selectedCategory?.childrenIds?.length

  if (isLeafCategory) {
    return removeLastPathNode(props?.selectedCategory?.path ?? '')
  }

  const parentId = sideCategories.value?.parentId as number

  const parent =
    Boolean(parentId) && categories?.value?.find((cat) => cat.id === parentId)

  if (parent && parent?.path) {
    return parent.path
  }

  return removeLastPathNode(sideCategories.value.path)
})

const childrenCategories = computed(() =>
  Array.isArray(sideCategories.value)
    ? []
    : (sideCategories.value.children ?? []),
)
</script>
